import React, {Fragment, ReactNode, useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import {CircularProgress, createStyles, makeStyles, Theme, Typography, useTheme} from "@material-ui/core";
import {ClassNameMap} from '@material-ui/styles/withStyles';
import * as firebase from "firebase/app";
import {useParams} from "react-router";
import {formatValueName, IValueType} from "@janitza/websdk-lib-value-formatter";
import EValue from "@janitza/websdk-lib-value-formatter/build/collections/EValue";
import {useIntl, IntlShape} from 'react-intl';
import {Link} from "react-router-dom";

const NUMBER_FORMAT_OPTS = {maximumFractionDigits: 2, minimumFractionDigits: 2};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 16,
        },
        card: {},
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        valuename: {},
        value: {
            fontFamily: "fixed"
        },
        unit: {
            fontFamily: "fixed",
            paddingLeft: 7
        },
        timestamp: {
            align: "right",
            fontSize: theme.typography.fontSize -6,
        }
    })
);

type OnlineValue = { value: number, time: number, unit?: string };

interface OnlineValues {
    [field: string]: OnlineValue;
}

type EValueStrings = keyof typeof EValue;

const buildValues = (intl: IntlShape, values: OnlineValues | undefined, classes: ClassNameMap<string>): ReactNode[] => {
    const result = [] as ReactNode[];
    for (const val in values) {
        const [value, type] = val.split('@');
        const vType = {
            value: EValue[value as EValueStrings],
            type
        } as IValueType;
        const valueTime = values[val];
        if (typeof valueTime.value === "number") {
            result.push(<Fragment key={val}>
                <Grid item xs={5} lg={4} zeroMinWidth>
                    <Typography color="textPrimary" noWrap variant={"overline"} className={classes.valuename}>
                        {formatValueName(vType)}:
                    </Typography>
                </Grid>
                <Grid item xs={3} lg={3} zeroMinWidth>
                    <Typography color="textPrimary" noWrap align={"right"} className={classes.value}>
                        {intl.formatNumber(valueTime.value, NUMBER_FORMAT_OPTS)}
                    </Typography>
                </Grid>
                <Grid item xs={1} lg={1} zeroMinWidth>
                    <Typography color="textPrimary" noWrap align={"left"} className={classes.unit}>
                        {valueTime?.unit}
                    </Typography>
                </Grid>
                <Grid item xs={3} lg={3} zeroMinWidth>
                    <Typography color="textSecondary" noWrap className={classes.timestamp}>
                        {intl.formatDate(valueTime.time / 1000000, {weekday: "short", day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit"})}
                    </Typography>
                </Grid>
            </Fragment>);
        }
    }
    return result;
};

export const DeviceOnlinePage: React.FC = () => {
    const database = firebase.app().database();
    const [values, setValues] = useState<OnlineValues>();
    const [loading, isLoading] = useState<boolean>(true);
    const classes = useStyles(useTheme());
    const {serialNr, hubId} = useParams();
    const intl = useIntl();
    useEffect(() => {
        isLoading(true);
        const refPath = `/Hubs/${hubId}/devices/${serialNr}/onlinevalues`;
        const func = database.ref(refPath).on("value", async function (snapshot) {
            const vals = snapshot.val();
            setValues(vals);
            isLoading(false);
        });
        return () => {
            database.ref(refPath).off("value", func)
        };
    }, [hubId, serialNr, database]);

    return (
        <>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.root}>
                {loading ? <CircularProgress/> : buildValues(intl, values, classes)}
            </Grid>
            <Link to={"/"}>back</Link>

        </>
    )
};
