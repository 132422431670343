import React from 'react'
import {
    Button,
    CardActions, CardContent, Collapse, createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment, makeStyles,
    TextField, Theme,
    Tooltip, useTheme
} from "@material-ui/core";
import {ExpandMore, NotificationsActive, NotificationsNone, Visibility, VisibilityOff, VpnKey, MultilineChart} from "@material-ui/icons";
import clsx from 'clsx';
import * as firebase from "firebase/app";
import {Device} from '../tools/DeviceDocument';
import { useSnackbar } from 'notistack';
import { debounce } from 'ts-debounce';
import {Link} from "react-router-dom";

interface Props {
    device: Device;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            maxWidth: 345,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    }),
);

const updateDeviceNameInFirebase = debounce( async (docPath: string, newName: string, enqueueSnackbar: any) => {
    const firestore = firebase.app().firestore();
    const doc = firestore.doc(docPath);
    try {
        await doc.set({deviceName: newName}, {merge: true});
        enqueueSnackbar("Device name updated", {variant: 'success'});
    } catch (e) {
        enqueueSnackbar("Device name update failed", { variant: 'error' });
    }
    firebase.analytics().logEvent('device_name_set');

}, 1000);

export function DeviceActions({device}: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [passwordState, setPasswordState] = React.useState({showPassword: false, password: ""});
    const [push, setPush] = React.useState(device.push);
    const classes = useStyles(useTheme());
    const [expanded, setExpanded] = React.useState(false);
    const [deviceName, setDeviceName] = React.useState(device.name);
    const docPath = `Hub/${device.hubId}/Devices/${device.serialNumber}`;

    const handleClickShowPassword = () => {
        setPasswordState({...passwordState, showPassword: !passwordState.showPassword});
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseOK = async () => {
        handleClose();
        const firestore = firebase.app().firestore();
        const doc = firestore.doc(docPath);
        try {
            await doc.set({credentials: {password: passwordState.password}}, {merge: true});
            enqueueSnackbar("Password updated", {variant: 'success'});
        } catch (e) {
            enqueueSnackbar("Password update failed", { variant: 'error' });
        }
        firebase.analytics().logEvent('device_password_set');
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleClickPushNotification = async () => {
        setPush(!push);
        const firestore = firebase.app().firestore();
        const userPath = `users/${device.userID}`;
        const doc = firestore.doc(userPath);
        try {
            await doc.set({
                devices: {[docPath]: !push}
            }, {merge: true});
            enqueueSnackbar(push ? "You will no longer receive updates for this device" : "You will receive updates for this device", {variant: 'success'});
        } catch (e) {
            enqueueSnackbar("Updating notification failed", { variant: 'error' });
        }
    };

    const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordState({...passwordState, password: event.target.value});
    };

    const handleDeviceNameChange = () => async (evt: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceName(evt.target.value);
        await updateDeviceNameInFirebase(docPath, evt.target.value, enqueueSnackbar);
    }

    return (<>
        <CardActions disableSpacing>
            <IconButton onClick={handleClickOpen} aria-label="add to favorites">
                <VpnKey/>
            </IconButton>
            <IconButton onClick={handleClickPushNotification} aria-label="add to favorites">
                {push && <Tooltip title="Deactivate Push Updates for GriCo"><NotificationsActive/></Tooltip>}
                {!push && <Tooltip title="Activate Push Updates for GriCo"><NotificationsNone/></Tooltip>}
            </IconButton>
            <IconButton aria-label="show online values" component={Link} to={`/device/${device.hubId}/${device.serialNumber}/online`}>
                <MultilineChart/>
            </IconButton>
            <IconButton
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMore />
            </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <TextField label={"Device name"} value={deviceName} onChange={handleDeviceNameChange()} />
            </CardContent>
        </Collapse>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Set device admin password"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Set admin password of device
                </DialogContentText>
                <TextField
                    type={passwordState.showPassword ? 'text' : 'password'}
                    value={passwordState.password}
                    onChange={handleChange()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKey/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {passwordState.showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleCloseOK} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </>);

}
