import React, {Fragment, useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import {BrowserRouter as Router, Link} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import {createStyles, makeStyles, MuiThemeProvider, Theme, useTheme} from '@material-ui/core/styles';
import 'typeface-roboto';
import './App.css';
import {SnackbarProvider} from 'notistack';
import {Frame, PrivateRoute} from "@janitza/firebase-ui";
import {DevicesPage} from "./component/DevicesPage";
import {blue, indigo} from "@material-ui/core/colors";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {User} from "firebase";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import {DeviceOnlinePage} from "./component/DeviceOnlinePage";
import translations from "./translations/translations.json";
import {FormattedMessage} from 'react-intl';
import i18n from "./App.i18n";
const localStorage = require("@janitza/websdk-lib-localstorage");

const storage = new localStorage.Storage();
const intl =  require("@janitza/websdk-lib-translations");

createMuiTheme({
    palette: {
        secondary: {
            main: blue[900]
        },
        primary: {
            main: indigo[700]
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Lato"',
            'sans-serif'
        ].join(',')
    }
});


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            margin: 10,
            width: 24,
            height: 24
        },
        logoutButton: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }),
);

interface Props {
}

const App: React.FC<Props> = () => {
    //firebase.messaging().onMessage((message) => console.log(message));
    storage.putHighPriority("locale", "de");
    const theme = useTheme();
    const [user, setUser] = useState<User | null>(() => {
        return firebase ? firebase.auth().currentUser : null
    });
    // const [messagingState, setMessagingState] = useState<string>("");
    // console.log("Use notification", firebase.app().remoteConfig().getBoolean("use_notification"));
    // useEffect( () => {
    //     if(messagingState !== "denied" && firebase.app().remoteConfig().getBoolean("use_notification")) {
    //         firebase.messaging().getToken().then((currentToken) => {
    //             setMessagingState(currentToken);
    //             console.log(currentToken);
    //         }).catch((e) => {
    //             //setMessagingState(e);
    //             Notification.requestPermission().then((p) => console.log(p)).catch((e) => console.log(e));
    //         });
    //     }
    // }, [user, messagingState]);
    const classes = useStyles(theme);
    if(user) {
        firebase.analytics().setUserId(user.uid);
        firebase.remoteConfig().fetchAndActivate().then((b) => {
            console.log("loaded ", b, firebase.remoteConfig().getBoolean("use_notification"));
        })
    }
    const writeProfile = (email: string, userName: string, photoURL: string) => {
        console.log("Writing profile")
        firebase.app().firestore().doc(`/users/${firebase.app().auth().currentUser?.uid}`).set({
            email,
            displayName: userName
        }, { merge: true }).catch((e) => {console.log(e)})
    }

    const userInfo = (
        <Fragment>{user ? (
            <Grid container spacing={0} alignItems={"center"} justify={"flex-end"}>
                <Grid item>
                    <Avatar className={classes.avatar} alt={user.displayName || ""} src={user.photoURL || ""}/>
                </Grid>
                <Grid item className={classes.logoutButton}>
                    <Button color="inherit" onClick={() => firebase && firebase.auth().signOut()}><FormattedMessage {...i18n.logout}/></Button>
                </Grid>
            </Grid>
        ) : (
            <Link to={"/login"}>Login</Link>
        )}</Fragment>);
    useEffect(() => {
        return firebase ? firebase.auth().onAuthStateChanged((user) => setUser(user)) : () => {
        };
    }, []);
    return (
        <Router>
            <CssBaseline/>
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider>
                    <intl.IntlProvider translations={translations}>
                        <Frame logout={() => {
                            firebase && firebase.auth().signOut()
                        }} userInfo={userInfo} title="Janitza&nbsp;Cloud">
                            <PrivateRoute path={"/"} exact component={DevicesPage} loginCallback={writeProfile}/>
                            <PrivateRoute path={"/device/:hubId/:serialNr/online"} component={DeviceOnlinePage} loginCallback={writeProfile}/>
                        </Frame>
                    </intl.IntlProvider>
                </SnackbarProvider>
            </MuiThemeProvider>
        </Router>
    );
};

export {App};