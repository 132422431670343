import { defineMessages } from "react-intl";

export default defineMessages({
    energy: {
        id: "energy",
        defaultMessage: "Energy",
        description: "Bottom navigator to display energy consumption graph. Needs to be short(<12).",
    },
    kpi: {
        id: "kpi",
        defaultMessage: "KPI",
        description: "Bottom navigator to display a KPI graph. Needs to be short(<12).",
    },
    water: {
        id: "water",
        defaultMessage: "Water",
        description: "Bottom navigator to display water consumption graph. Needs to be short(<12)",
    },
    gas: {
        id: "gas",
        defaultMessage: "Gas",
        description: "Bottom navigator to display gas consumption graph. Needs to be short(<12)",
    },
    lastSevenDays: {
        id: "last_seven_days",
        defaultMessage: "Last 7 days",
        description: "Title of graph showing the last seven days of consumption",
    },
});