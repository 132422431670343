import React, { useState, useEffect } from 'react'
import Grid from "@material-ui/core/Grid";
import {CircularProgress, createStyles, makeStyles, useTheme} from "@material-ui/core";
import {DeviceDetails} from './DeviceDetails';
import * as firebase from "firebase/app";
import {DeviceActions} from "./DevicesActions";
import {convertDevice, Device} from "../tools/DeviceDocument";

const useStyles = makeStyles(() =>
    createStyles( {
    root: {
        marginTop: 16,
    },
    card: {

    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
})
);

interface Props {
}

export const DevicesPage: React.FC<Props> = () => {
    const firestore = firebase.app().firestore();
    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, isLoading] = useState<boolean>(true);
    const classes = useStyles(useTheme());
    const auth = firebase.auth().currentUser;
    const uid = auth ? auth.uid : "";
    useEffect(() => {
        isLoading(true);
        return firestore.collectionGroup('Devices').where('owner', '==', uid).onSnapshot( async function(snapshot) {
            const ndevices: Device[] = [];
            const user = await firestore.doc(`/users/${uid}`).get();
            snapshot.forEach(function (doc) {
                ndevices.push(convertDevice(doc.data(), user.data()));
            });
            setDevices(ndevices);
            isLoading(false);
        })
    }, [uid, firestore]);

    return (
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className={classes.root}>
                <Grid item xs={11} lg={7}>
                    {devices.map((device, id) => <DeviceDetails key={id} device={device} cardActions={<DeviceActions device={device}/>}/>)}
                    { loading && <CircularProgress size={22}/> }
                </Grid>
            </Grid>
        )
};
