import Highcharts, {SeriesOptionsType} from "highcharts";
import moment from "moment";
import i18n from "./DeviceDetails.i18n";
import {Device} from "../tools/DeviceDocument";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import {useIntl, IntlShape} from 'react-intl';
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core";
import {getConsumptionForDate} from "../tools/DeviceDocument";

const CHART_HEIGHT = 250;
const ISO_DATE = 'YYYY-MM-DD';

interface Props {
    device: Device;
    consumptionSelection: string;
}

function graphOptions(intl: IntlShape, device: Device, consume: string): Highcharts.Options {
    const date = moment().subtract(6, "day");
    const now = moment();
    const categories = [];
    const dataEnergy = [];
    const dataGas = [];
    const dataWater = [];
    const dataKPI = [];
    const makeKilo = (value: number|undefined) => typeof value === "number" ? value / 1000 : 0;
    while(date.isSameOrBefore(now)) {
        const dateStr = date.format(ISO_DATE);
        categories.push(date.format("dd, L"));
        dataEnergy.push(makeKilo(getConsumptionForDate(device, "energy", dateStr)));
        dataGas.push(getConsumptionForDate(device, "gas", dateStr));
        dataWater.push(getConsumptionForDate(device, "water", dateStr));
        dataKPI.push(getConsumptionForDate(device, "kpiResult", dateStr));
        date.add(1, "day");
    }
    const series = [];
    let unit = "";
    if(device.energy && consume === "energy") {
        series.push({
            data: dataEnergy,
            type: "column",
            name: intl.formatMessage(i18n.energy),
        } as SeriesOptionsType);
        unit = "kWh";
    }
    if(device.gas && consume === "gas") {
        series.push({
            data: dataGas,
            type: "column",
            name: intl.formatMessage(i18n.gas),
        } as SeriesOptionsType);
        unit = "m³";
    }
    if(device.water && consume === "water") {
        series.push({
            data: dataWater,
            type: "column",
            name: intl.formatMessage(i18n.water),
        } as SeriesOptionsType);
        unit = "l";
    }
    if(device.kpiResult && consume === "kpi") {
        series.push({
            data: dataKPI,
            type: "column",
            name: intl.formatMessage(i18n.kpi),
        } as SeriesOptionsType);
        unit = "";
    }
    return {
        title: {
            text: intl.formatMessage(i18n.lastSevenDays)
        },
        xAxis: {
            categories
        },
        yAxis: {
            title: { text: `[${unit}]`},
        },
        tooltip: {
            pointFormat: `{point.y:.2f} ${unit}`
        },
        series,
        credits: {enabled: false},
        legend: { enabled: false},
        chart: {
            height: CHART_HEIGHT,
        }
    } as Highcharts.Options;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        graph: {
            height: CHART_HEIGHT,
        }
    }));

export function ConsumptionGraph({device, consumptionSelection}: Props) {
    const classes = useStyles(useTheme());
    const intl = useIntl();
    const options = graphOptions(intl, device, consumptionSelection);

    return (
        <div className={classes.graph}>
            <HighchartsReact highcharts={Highcharts} options={options} className={classes.graph}/>
        </div>
    );
}