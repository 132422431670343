import React, {ReactNode, useState} from 'react'
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Tooltip,
    Typography, useTheme
} from "@material-ui/core";
import moment from "moment";
import 'moment/locale/de';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import FireplaceIcon from '@material-ui/icons/Fireplace';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import PowerIcon from '@material-ui/icons/Power';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {useIntl, IntlShape} from 'react-intl';
import i18n from "./DeviceDetails.i18n";
import {ConsumptionGraph} from "./ConsumptionGraph";
import {Device, getConsumptionForDate} from "../tools/DeviceDocument";

moment().locale('de');

const NUMBER_FORMAT_OPTS = {maximumFractionDigits: 2};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        card: {
            margin: 5,
        },
        title: {
            fontSize: 14
        },
        pos: {
            marginBottom: 1,
        },
        avatar: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.getContrastText(theme.palette.primary.light)
        },
    }));

interface Props {
    device: Device;
    cardActions?: ReactNode;
}

function formatValueAsKilo(intl: IntlShape, val: number): string {
    return intl.formatNumber(val / 1000, NUMBER_FORMAT_OPTS);
}

function formatEnergyForDate(intl: IntlShape, device: Device, date: string): string {
    let result = "--";
    const energy = getConsumptionForDate(device, "energy", date);
    if(energy) {
        result = formatValueAsKilo(intl, energy) + " kWh";
    }
    return result;
}

function formatPower(intl: IntlShape, val: number): string {
    return formatValueAsKilo(intl, val) + " kW";
}

const ISO_DATE = 'YYYY-MM-DD';

function initialConsumer(device: Device) : string {
    if(device.energy) return "energy";
    if(device.kpiResult) return "kpi";
    if(device.water) return "water";
    if(device.gas) return "gas";
    return ""
}

export function DeviceDetails({device, cardActions}: Props) {
    const classes = useStyles(useTheme());
    const intl = useIntl();
    const todayString = moment().format(ISO_DATE);
    const yesterdayString = moment().subtract(1, "day").format(ISO_DATE);
    const todayEnergyString = formatEnergyForDate(intl, device, todayString);
    const yesterdayEnergyString = formatEnergyForDate(intl, device, yesterdayString);
    const [consumeSelection, setConsume] = useState(initialConsumer(device));

    const handleConsumeChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setConsume(newValue);
    };
    return (
        <Card className={classes.card}>
            <CardHeader avatar={
                <Avatar className={classes.avatar}>{device.name.substr(0, 1)}</Avatar>
            }
                        title={device.name}
                        subheader={`${device.type} - ${device.serialNumber}`}
            />
            <CardContent>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className={classes.root}>
                    {
                        device.firmwareVersion ?
                            <>
                                <Grid item xs={8} lg={5} zeroMinWidth>
                                    <Typography className={classes.title} color="textPrimary" noWrap>
                                        Firmware(V):
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={5} zeroMinWidth>
                                    <Typography className={classes.title} color="textPrimary" noWrap>
                                        {device.firmwareVersion}
                                    </Typography>
                                </Grid>
                            </>
                        :
                            <></>
                    }
                    <Grid item xs={8} lg={5}>
                        <Tooltip title="Energy consumption Yesterday">
                            <Typography className={classes.title} color="textSecondary" noWrap>
                                Energy consumption Yesterday:
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4} lg={5}>
                        <Tooltip title={yesterdayEnergyString}>
                            <Typography className={classes.title} color="textSecondary">
                                {yesterdayEnergyString}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={8} lg={5}>
                        <Tooltip title="Energy consumption Today">
                            <Typography className={classes.title} color="textPrimary" noWrap>
                                Energy consumption Today:
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4} lg={5}>
                        <Tooltip title={todayEnergyString}>
                            <Typography className={classes.title} color="textPrimary">
                                {todayEnergyString}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    {
                        device.peekPower ?
                            <>
                                <Grid item xs={8} lg={5}>
                                    <Tooltip title="Energy consumption Today">
                                        <Typography className={classes.title} color="textPrimary" noWrap>
                                            Peek Power Today:
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={4} lg={5}>
                                    <Tooltip title={todayEnergyString}>
                                        <Typography className={classes.title} color="textPrimary">
                                            {formatPower(intl, device.peekPower)}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </>
                            :
                            <></>
                    }
                </Grid>
            </CardContent>
            {consumeSelection !== "" ? <ConsumptionGraph device={device} consumptionSelection={consumeSelection}/> :<></>}
            <BottomNavigation value={consumeSelection} onChange={handleConsumeChange}>
                {device.energy ? <BottomNavigationAction label={intl.formatMessage(i18n.energy)} value="energy" icon={<PowerIcon />}/> : <></>}
                {device.kpiResult ? <BottomNavigationAction label={intl.formatMessage(i18n.kpi)} value="kpi" icon={<TrendingUpIcon />}/> : <></>}
                {device.water ? <BottomNavigationAction label={intl.formatMessage(i18n.water)} value="water" icon={<LocalDrinkIcon />}/>: <></>}
                {device.gas ? <BottomNavigationAction label={intl.formatMessage(i18n.gas)} value="gas" icon={<FireplaceIcon />}/> : <></>}
            </BottomNavigation>
            {cardActions}
        </Card>
    )
}
