import moment from "moment";

export interface Device {
    name: string;
    type: string;
    serialNumber?: string;
    firmwareVersion: string;
    hubId: string;
    energy: { [field: string]: number };
    water: { [field: string]: number };
    gas: { [field: string]: number };
    kpiResult: { [field: string]: number };
    peekPower?: number;
    userID: string;
    push: boolean;
}


export function getConsumptionForDate(device: Device, consumption: "energy" | "gas" | "water" | "kpiResult", date: string): number | undefined {
    let result = Number.NaN;
    if(device[consumption]) {
        const consumed = device[consumption][date];
        if(consumed && ! Number.isNaN(consumed))
            result = consumed;
    }
    return result;
}

export function convertDevice(deviceData: any, userData: any): Device {
    const todayString = moment().format('YYYY-MM-DD');

    const device = {
        name: deviceData.deviceName || "",
        type: deviceData.deviceType,
        serialNumber: deviceData.serialNumber,
        firmwareVersion: deviceData.firmwareVersion,
        hubId: deviceData.hubId,
        energy: deviceData.energy,
        gas: deviceData.gas,
        water: deviceData.water,
        kpiResult: deviceData.kpiResult,
        peekPower: deviceData.activePower ? deviceData.activePower[todayString] : Number.NaN,
        userID: deviceData.owner,
        push: false
    } as Device;
    if (userData && userData.devices) {
        const userDeviceMarker = `Hub/${device.hubId}/Devices/${device.serialNumber}`;
        device.push = userData.devices[userDeviceMarker];
    }

    return device;
}
